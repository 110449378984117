*, *:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: 'Roboto Slab', serif;
  font-size: 10px;
  text-align: center;
  background: #faf7f0;
}

h1, h2 {
  font-weight: normal;
}

h1 {
  font-size: 2rem;
}

#app {
  padding: 4rem;
}

a {
  color: inherit;
  text-decoration-color: #eb6565;
  text-decoration-thickness: 0.2rem;
  transition: opacity .2s ease;
}

.no-touchevents a:hover {
  opacity: 0.5;
}
